import React, { useState } from 'react';
import axios from 'axios';
import { ReactTyped } from 'react-typed';
import Github from '../../img/facebookorange.png';
import messenger from '../../img/facebookmessenger.png';
import LinkedIn from '../../img/twitter.png';
import Instagram from '../../img/insta.png';
import GiftForm from './GiftForm';
import Carousel from './Carousel/Slider';
import FetchImage from './FetchImage';

const ITEMS_PER_PAGE = 3;

const Intro = () => {
  const [showGiftProducts, setShowGiftProducts] = useState(false);
  const [giftIdeas, setGiftIdeas] = useState([]);
  const [error, setError] = useState("");
  const [showGiftIdeasContainer, setShowGiftIdeasContainer] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState("");

  const handleButtonClick = () => setShowGiftProducts(true);

  const handleReviewClick = (review) => {
    setSelectedPrompt(review);
    setFormData((prevData) => ({
      ...prevData,
      prompt: review,
    }));
  };

  const handleGenerateGiftIdeas = (ideas, error) => {
    setGiftIdeas(ideas);
    setError(error);
    setShowGiftIdeasContainer(true);
    setCurrentPage(0);
  };

  const handleFormDataChange = (data) => {
    setFormData(data);
  };

  const handleGenerateMore = async () => {
    if (!formData) {
      setError("Form data is missing");
      return;
    }
    setIsLoadingMore(true);
    try {
      const response = await axios.post("https://shlokverma9828.pythonanywhere.com/generate_more_gift_ideas", formData);
      setGiftIdeas([...giftIdeas, ...response.data.gift_ideas]);
      setError("");
    } catch (error) {
      setError("Error generating more gift ideas");
    }
    setIsLoadingMore(false);
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * ITEMS_PER_PAGE < giftIdeas.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = currentPage * ITEMS_PER_PAGE;
  const displayedIdeas = giftIdeas.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handleShowGiftIdeasContainer = () => {
    setShowGiftIdeasContainer(true);
  };

  return (
    <div>
      <div className="flex flex-col lg:flex-row ml-4 md:ml-10 md:mt-24 pb-10 md:pb-20 pt-8 md:pt-13">
        <div className="flex flex-col flex-1 gap-8 items-start">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-3xl md:text-5xl mt-5 md:mt-10 text-orange-400 fuzzy-bubbles-bold">GiftGuru</p>
            <div className="flex justify-center items-center text-2xl md:text-5xl mt-3 md:mt-5">
              <p className="text-lg md:text-xl inline-block open-sans-regular">
                The ultimate destination for discovering the perfect gift for every occasion, offering an unparalleled selection of thoughtfully curated treasures for your
                <ReactTyped
                  className="inline-block text-orange-400 font-bold ml-1"
                  style={{ fontSize: "1.5rem", md: { fontSize: "1.875rem" } }}
                  strings={['Husband', 'Wife', 'Son', 'Daughter', 'Mother', 'Father', 'Friend']}
                  typeSpeed={120}
                  backSpeed={140}
                  loop
                />
              </p>
            </div>
          </div>

          <div className="flex gap-3">
            <img src={LinkedIn} alt="LinkedIn" className="w-6 h-6 md:w-8 md:h-8 transform hover:scale-110 transition-transform duration-300" />
            <img src={Instagram} alt="Instagram" className="w-6 h-6 md:w-8 md:h-8 transform hover:scale-110 transition-transform duration-300" />
            <img src={Github} alt="Github" className="w-6 h-6 md:w-8 md:h-8 transform hover:scale-110 transition-transform duration-300" />
            <img src={messenger} alt="Messenger" className="w-6 h-6 md:w-8 md:h-8 transform hover:scale-110 transition-transform duration-300" />
          </div>
          <div className="max-w-full lg:max-w-2xl p-2 bg-white shadow-md rounded-md hidden lg:block">
            <Carousel onReviewClick={handleReviewClick} />
          </div>
        </div>

        <div className="flex flex-1 relative lg:mt-0 mt-10">
          <div className="max-w-full lg:max-w-2xl ml-0 md:ml-10 p-8 bg-white shadow-md rounded-md">
            <GiftForm
              onGenerateGiftIdeas={handleGenerateGiftIdeas}
              onFormDataChange={handleFormDataChange}
              initialPrompt={selectedPrompt}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              onShowGiftIdeasContainer={handleShowGiftIdeasContainer}
            />
          </div>
        </div>
      </div>

      {showGiftIdeasContainer && (
        <div className="flex justify-center items-center min-h-screen bg-gray-50 mt-10">
          <div className="bg-white text-black p-4 shadow-md rounded-md w-full max-w-6xl">
            <h2 className="text-2xl mb-4 text-orange-400 fuzzy-bubbles-bold">Generated Gift Ideas</h2>
            {error && <p className="text-red-500">{error}</p>}
            {isLoading ? (
              <div className="flex flex-col items-center">
                <div className="spinner border-t-4 border-orange-400 rounded-full w-12 h-12 animate-spin"></div>
                <div className="mt-4 flex flex-col items-center w-full">
                  <div className="flex justify-between w-full mb-4">
                    <button disabled className="bg-orange-400 text-white px-4 py-2 rounded opacity-50 cursor-not-allowed">
                      Previous
                    </button>
                    <button disabled className="bg-orange-400 text-white px-4 py-2 rounded opacity-50 cursor-not-allowed">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {displayedIdeas.map((idea, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-md shadow-sm flex flex-col justify-between">
                      <FetchImage query={idea.Product_name} style={{ width: '100%', height: '100px', objectFit: 'cover' }} />
                      <h3 className="fuzzy-bubbles-bold mb-1 text-orange-400">{idea.Product_name}</h3>
                      <p className="text-sm mb-2">{idea.Reason}</p>
                      <a href={`https://www.amazon.in/s?k=${idea.Product_name}+${idea.Company}&tag=genaigifts-21`} target="_blank" rel="noopener noreferrer" className="text-orange-400 hover:underline">View on Amazon</a>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between mt-4">
                  <button onClick={handlePreviousPage} disabled={currentPage === 0 || isLoading} className="bg-orange-400 text-white px-4 py-2 rounded disabled:opacity-50">
                    Previous
                  </button>
                  <button onClick={handleNextPage} disabled={(currentPage + 1) * ITEMS_PER_PAGE >= giftIdeas.length || isLoading} className="bg-orange-400 text-white px-4 py-2 rounded disabled:opacity-50">
                    Next
                  </button>
                </div>
                <div className="mt-4">
                  <button onClick={handleGenerateMore} className="bg-orange-400 text-white px-4 py-2 rounded" disabled={isLoadingMore || isLoading}>
                    {isLoadingMore ? 'Loading...' : 'Generate More'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Intro;