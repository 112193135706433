/** @format */

export const faqs = [
  {
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.",
    isAccordionOpen: false
  },
  {
    question: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
    answer:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    isAccordionOpen: false
  },
  {
    question: "Excepteur sint occaecat cupidatat non proident?",
    answer:
      "Sunt in culpa qui officia deserunt mollit anim id est laborum. Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.",
    isAccordionOpen: false
  },
  {
    question: "Ut enim ad minim veniam, quis nostrud exercitation ullamco?",
    answer:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    isAccordionOpen: false
  }
];
